<div class="filters-desktop-container">
  <div class="close-modal" (click)="closeModal()">
    <img src="assets/icon/blue-close.svg" alt="" />
  </div>

  <div class="title">{{ 'PWA_libraryFilters_language_desktop_header' | translate }}</div>
  <div class="description">{{ 'PWA_libraryFilters_language_desktop_subtitle' | translate }}</div>

  <ion-radio-group [value]="selectedLanguageCode" (ionChange)="onSelectedLanguageChange($event)">
    <ion-radio mode="md" justify="start" label-placement="end" [value]="null"> {{ 'PWA_homeLibrary_filters_language' | translate }} </ion-radio>
    @for (languageFilter of languageFilters; track $index) {
      <ion-radio mode="md" justify="start" label-placement="end" [value]="languageFilter.code"> {{ languageFilter.name }} </ion-radio>
    }
  </ion-radio-group>

  <app-button [label]="'PWA_libraryFilters_ageRange_desktop_CTA_confirm' | translate" (clicked)="applyFilter()" />

  <div class="cancel" (click)="closeModal()">{{ 'PWA_libraryFilters_ageRange_desktop_CTA_link_cancel' | translate }}</div>
</div>
