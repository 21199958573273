<app-button
  [size]="'xsmall'"
  [color]="'blue'"
  [label]="selectedGrade$ | async"
  [icon]="'assets/icon/person-white.svg'"
  [iconPlacement]="'end'"
  [shape]="(appService.isMobile$ | async) && !(bookFiltersState.bookFilters$ | async)?.selectedGrade ? 'circle' : 'default'"
  (clicked)="displayAgeFilter()" />

<app-button
  [size]="'xsmall'"
  [color]="'orange'"
  [label]="selectedLanguage$ | async"
  [icon]="'assets/icon/globe.svg'"
  [iconPlacement]="'end'"
  [shape]="(appService.isMobile$ | async) && !(bookFiltersState.bookFilters$ | async)?.selectedLanguage ? 'circle' : 'default'"
  (clicked)="displayLanguageFilter()" />
