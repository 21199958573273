<div class="filters-mobile-container">
  <div class="title">{{ 'PWA_libraryFilters_language_header' | translate }}</div>

  <div class="options-container">
    <div class="option" (click)="onSelectedLanguageChange(null)">
      {{ 'PWA_homeLibrary_filters_language' | translate }}
      @if (!selectedLanguageCode) {
        <img src="assets/icon/blue-check.svg" alt="" />
      }
    </div>

    @for (languageFilter of languageFilters; track $index) {
      <div class="option" (click)="onSelectedLanguageChange(languageFilter.code)">
        {{ languageFilter.name }}
        @if (selectedLanguageCode === languageFilter.code) {
          <img src="assets/icon/blue-check.svg" alt="" />
        }
      </div>
    }
  </div>
</div>
